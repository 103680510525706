// import OffMarketFormModal from "@components/OffMarketFormModal/OfficeContactModal"
import { usePropertyStore } from "@components/SearchResults/store/propertyStore"
import Animation from "@components/elements/Animation"
import CTALink from "@components/elements/CTALink"
import GGFXImage from "@components/elements/GGFXImage"
import useMarketingBanners from "@hooks/dataHooks/useMarketingBanners"
import usePropertyBanners from "@hooks/dataHooks/usePropertyBanners"
import { parseHTML } from "@lib/utils"
import loadable from "@loadable/component"
import clsx from "clsx"
import { isEmpty } from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import "./AdsBanner.scss"

const AdsBanner = ({
  smallBanner,
  cardBanner,
  noContainer,
  module,
  propertyIndex,
  listView,
  propertyBanner,
  isProperty,
  isNews
}) => {
  const searchResult = usePropertyStore((state) => state.searchResult)
  const { hits, page } = searchResult
  let cardOffset = 0
  if(listView) {
     cardOffset = hits?.length > 4 ? 4 : hits?.length > 1 ? 2 : 0
  }
  else {
    cardOffset = hits?.length > 9 ? 9 : hits?.length > 3 ? 3 : 0
  }

  const propertyBanners = usePropertyBanners()

  let marketingBanners = useMarketingBanners()
  if (propertyBanner) {
    marketingBanners = propertyBanners[propertyBanner]
  }

  const marketingBanner = module?.marketing_banner

  if (isEmpty(marketingBanners)) return null

  let bannerData = marketingBanner || marketingBanners[0]

  if (typeof propertyIndex === "number") {
    if (!isEmpty(searchResult) && (propertyIndex + 1) % cardOffset === 0 && (propertyIndex + 1 !== 27)) {
      
      const hitsPerPage = hits?.length || 0

      const currentPagePropertyIndex = page * hitsPerPage + propertyIndex

      const bannerTempIndex = Math.floor(currentPagePropertyIndex / cardOffset)

      const bannerIndex = Math.floor(bannerTempIndex % marketingBanners.length)

      if (bannerIndex > marketingBanners.length) {
        console.error(
          `Invalid banner index ${bannerIndex} for property index ${propertyIndex}.
          Max banner index is ${marketingBanners.length - 1}.`
        )

        return null
      }

      bannerData = marketingBanners[bannerIndex]
    } else return null
  }

  if (isEmpty(bannerData)) return null

  const {
    title,
    description,
    bg_image,
    cta,
    cta1,
    heading,
    ggfx_results,
    strapi_id: strapiID,
    icon,
    style
  } = bannerData


  const Wrapper = noContainer ? "div" : Container

  let CTA = null

  if (cta) {
    if (cta?.custom_link === "$off-market-popup") {
      const OffMarketFormModal = loadable(() =>
        import("@components/OffMarketFormModal/OffMarketFormModal")
      )
      CTA = (
        <OffMarketFormModal noScrollOnSuccess>
          <span>{cta?.cta_label}</span>
        </OffMarketFormModal>
      )
    } else {
      CTA = <CTALink cta={cta} className="button button-orange" />
    }
  }

  return (
    <Animation
      className=""
      wrapperClassName={clsx(
        "ads-banner-wrap",
        !style ? "section-m" : style ? "section-m" : "",
        (smallBanner || listView || isProperty) && "ads-banner-wrap-small",
        cardBanner && !listView || !isProperty && "ads-banner-wrap-card",
        isNews && "ads-banner-wrap-card",
      )}
    >
      
      <Wrapper className={"ads-banner-container " +style}>
        <div className="gradient-overlay">
          <div className="banner-section">
            <div className="bg-img">
              <GGFXImage
                ImageSrc={bg_image}
                altText={title}
                imagetransforms={ggfx_results}
                renderer="srcSet"
                imagename="marketing-banner.bg_image.ads_banner"
                strapiID={strapiID}
              />
            </div>
            <div className="content-section">
              <div className="content">
                {/* {icon?.url && (
                  <img
                    src={icon?.url}
                    alt="banner icon"
                    className="banner-icon"
                  />
                )} */}
                {heading && <p className="heading">{heading}</p>}
                <p className="title">{title}</p>
                {description?.data?.description && (
                  <div className="description">
                    {parseHTML(description.data.description)}
                  </div>
                )}
              </div>
              <div className={cta1?.cta_label? "cta-section cta-flex" : "cta-section"}>{CTA}
              <CTALink cta={cta1} className="button button-orange btn2" />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Animation>
  )
}

export default AdsBanner
